import { connect, ConnectedProps } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { logoutUser } from "../../redux/reducers/UserReducer";
import { RootState } from "../../redux/store";
import { Nav } from "./components";
import classes from "./dashboard.module.scss";
import { GeneralOverview } from "./subPages/generalOverview";
const mapState = (state: RootState) => ({
    user: state.user
})

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux

export function dashboardComponent({ dispatch, user }: Props) {

    
    if(!user.id) {
        console.log(user)
        if(user.status == 'fetching') return <Loader />
        else return <Redirect to="/" />
    }
    
    return <div className={classes.dashboardContainer}>
        <Nav user={user} logout={() => dispatch(logoutUser())} />
        <div className={classes.container}>
            <Switch>
                <Route path="/dashboard" exact>
                    <GeneralOverview />
                </Route>
            </Switch>
        </div>
    </div>
}

export const DashboardPage = connector(dashboardComponent);
