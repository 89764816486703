import React, { ChangeEvent } from "react";
import { toast } from "react-toastify";
import { updateUserSecurity } from "../../../utils/quickRequests";
import classes from "./styles/main.module.scss";

type Props = { close: () => void, refreshUser: () => void }

export function EmailModal ({ close, refreshUser }: Props) {

    let [data, setData] = React.useState({ email: '', currentPassword: "" });

    const onChange = (ev: ChangeEvent) => {
        ev.preventDefault();
        let key = ev.target.id,
        // @ts-ignore
            val = ev.target.value;

            setData({
            ...data,
            [key]: val
    })};

    const save = async () => {
        if(data.email.length < 3) return toast.error('Too short email');
        if(data.currentPassword.length <3) return toast.error('Too short current password');

        let result = await updateUserSecurity({
            currentPassword: data.currentPassword,
            key: 'email',
            value: data.email
        });

        if(result) {
            refreshUser();
            close()
        }

        
    }

    return <div className={classes.modalWrapper}>
        <div className={classes["modalContent"]}>
            <h2>Enter an email address</h2>
            <ul>
                <li>
                    <label htmlFor="email">Email:</label>
                    <input onChange={(e) => onChange(e)} type="email" id="email" />
                </li>
                <li>
                    <label htmlFor="currentPassword">Current password:</label>
                    <input onChange={(e) => onChange(e)} type="password" id="currentPassword" />
                </li>
            </ul>
        </div>
        <footer className={classes["modalFooter"]}>
            <a href="#" onClick={() => close()}>Cancel</a>
            <button className={classes.save} onClick={() => save()}>Save</button>
        </footer>
    </div>
}