import { IUserState } from "../../../../interfaces";
import classes from "./Nav.module.scss";
import Logopath from "../../../../assets/img/logo.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { formatAvatar } from "../../../../utils/format";

export function Nav({ user, logout }: { user: IUserState, logout: () => void }) {
    return <nav className={classes.nav}>
        <header>
            <img className={classes.logo} src={Logopath} alt="LOGO" />
        </header>
        <section id="pages">
            <Link to="/dashboard">
                <img srcSet={formatAvatar(user.id || 0, user.avatar || undefined)} src={user.avatar || ""} width="36" height="36" className={classes.avatar} alt="" />
            </Link>
        </section>
        <footer>
            <FontAwesomeIcon icon={faSignOutAlt} className={classes.logout} onClick={() => logout()} />
        </footer>
    </nav>
}