import { configureStore } from '@reduxjs/toolkit'
import ConnectionsReducer from './reducers/ConnectionsReducer'
import OAuthReducer from './reducers/OAuthReducer'
import UserReducer from './reducers/UserReducer'

export const store = configureStore({
  reducer: {
    user: UserReducer,
    connections: ConnectionsReducer,
    oauth: OAuthReducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch