import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { IUser, IUserState } from '../../interfaces'
import { IConnection } from '../../interfaces/IConnection';
import { ConnectionState } from '../../interfaces/IConnection/IConnectionState';
import { OauthState } from '../../interfaces/Oauth';
import { RootState } from '../store'

const initialState: OauthState = {
    user: null,
    application: null,
    status: 'idle'
};

export const fetchOauthClient = createAsyncThunk('oauth/fetch', async (query: string) => {
    let connections = await axios.get('/api/oauth2/authorize' + query, { headers: { 'authorization': `Bearer ${localStorage.getItem('token')}` } })
        .then(res => res.data)

    return connections;
})

export const oauthSlice = createSlice({
    name: 'oauth',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchOauthClient.pending, (state, action) => {
            state.status = 'fetching'
            return state;
        }).addCase(fetchOauthClient.rejected, (state, action) => {
            console.log('ERRRRRORRRR', action)
            state.status = 'rejected'
            return state;
        }).addCase(fetchOauthClient.fulfilled, (state, action) => {
            state.user = action.payload.user;
            state.application = action.payload.application;
            state.status = 'idle'
            return state;
        })
    }
})
  

export const {} = oauthSlice.actions

export const selectOauth = (state: RootState) => state.oauth

export default oauthSlice.reducer