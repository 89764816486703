export function formatAvatar(id: number, avatarHash?: string) {
    if(!avatarHash) return `/media/avatar/0/default.png`;
    let basePath = `/media/avatar/{{id}}/{{avatarHash}}.png`;

    if(avatarHash.includes('.png') 
        || avatarHash.includes('.jpeg') 
        || avatarHash.includes('.jpg')
        || avatarHash.includes('.gif')) return avatarHash;

    return basePath.replace(/{{id}}/, id.toString())
        .replace(/{{avatarHash}}/, avatarHash)
}