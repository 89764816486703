import React, { ChangeEvent } from 'react';
import classes from "./index.module.scss";
import Logo from "../../assets/img/logo.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { registerAndGetToken } from '../../utils/quickRequests';
import { connect, ConnectedProps } from 'react-redux';
import { fetchUser } from '../../redux/reducers/UserReducer';
import { RootState } from '../../redux/store';
import { Link, Redirect } from 'react-router-dom';
import LogoFull from "../../assets/img/logo_full.svg";
import { fetchConnections } from '../../redux/reducers/ConnectionsReducer';
const mapState = (state: RootState) => ({
    user: state.user
})

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux

const SignUpPage = ({ dispatch, user }: Props) => {
    
    const [userData, setUserdata] = React.useState({ email: '', password: '', username: '' });

    if(user.id || localStorage.getItem('token')) {
        let backUrl = localStorage.getItem('backUrl');
        if(backUrl) {
            localStorage.removeItem('backUrl');
            return <Redirect to={backUrl} />
        }
        return <Redirect to="/dashboard" />
    }

    const onSubmit = async (ev: React.FormEvent) => {
        ev.preventDefault();

        if(userData.email.length < 3) return toast.error('Too short email...')
        if(userData.password.length < 3) return toast.error('Too short password...')
        if(userData.username.length < 3) return toast.error('Too short password...')
        let token = await registerAndGetToken(userData);
        // @ts-ignore
        dispatch(fetchUser(token));
        // @ts-ignore
        dispatch(fetchConnections(token));
        console.log(user)
    }

    const onChange = (ev: ChangeEvent) => {
        ev.preventDefault();
        let key = ev.target.id,
        // @ts-ignore
            val = ev.target.value;

        setUserdata({
            ...userData,
            [key]: val
        });
    }

    return <div className={classes.page}>
        <div className={classes.logo}>
            <picture>
                <source srcSet={LogoFull} media="(max-width: 750px)" />
                <img src={Logo} alt="LOGOTYPE" />
            </picture>
        </div>

        <form className={classes.form} onSubmit={(e) => onSubmit(e)}>
            <header>
                Sign-Up
            </header>

            <ul className={classes.fields}>
            <li className={classes.field}>
                    <input minLength={3} maxLength={32} value={userData.username} type="username" id="username" onChange={(e) => onChange(e)} />
                    <FontAwesomeIcon icon={faUser} className={classes.inputIcon} />
                </li>
                <li className={classes.field}>
                    <input minLength={3} maxLength={32} value={userData.email} type="email" id="email" onChange={(e) => onChange(e)} />
                    <FontAwesomeIcon icon={faEnvelope} className={classes.inputIcon} />
                </li>
                <li className={classes.field}>
                    <input minLength={6} maxLength={32} type="password" id="password" onChange={(e) => onChange(e)} />
                    <FontAwesomeIcon icon={faLock} className={classes.inputIcon} />
                </li>
            </ul>

            <footer>
                <button className={classes.agreeButton}>Sign-Up</button>
                <Link to="/">Already registered?</Link>
            </footer>
        </form>
    </div>
}

export const SignUp = connector(SignUpPage);