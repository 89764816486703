import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { IUserLoginCredentials, IUserRegisterCredentials } from "../interfaces";
import { fetchUser, getUser, selectUser } from "../redux/reducers/UserReducer";

export async function getToken(data: IUserLoginCredentials) {
    return await axios.post(`/api/auth/`, data).then(async res => {
        let { access_token }: { access_token: string } = res.data;
        if(access_token) {
            localStorage.setItem('token', access_token);
            // @ts-ignore
            return access_token;
        }
        return null;
    }).catch(e => {
        if(e.response) {
            toast.error(e.response.data.message)
        } else {
            toast.error('Uncaught exception.')
        }        return null
    });
}

export async function registerAndGetToken(data: IUserRegisterCredentials) {
    return await axios.put(`/api/auth/reg`, data).then(async res => {
        let { access_token }: { access_token: string } = res.data;
        if(access_token) {
            localStorage.setItem('token', access_token);
            // @ts-ignore
            return access_token;
        }
        return null;
    }).catch(e => {
        if(e.response) {
            toast.error(e.response.data.message)
        } else {
            toast.error('Uncaught exception.')
        }        return null
    });
}

type UserSecuritySettings = {
    key: 'email' | 'password'
    value: string,
    currentPassword: string
}

export async function updateUserSecurity(data: UserSecuritySettings) {
    let token = localStorage.getItem('token')
    return await axios.patch(`/api/users/@me/security`, {
        currentPassword: data.currentPassword,
        [data.key]: data.value
    }, {
        headers: { 'authorization': 'Bearer ' + token }
    }).then(async res => {
        toast.success('Applied')
        return true;
    }).catch(e => {
        if(e.response) {
            toast.error(e.response.data.message)
        } else {
            toast.error('Uncaught exception.')
        }        return false;
    });
}