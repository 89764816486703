import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import "./modals.scss"
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./app/redux/store"
import { Provider } from 'react-redux';
import { store } from './app/redux/store';

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
  </React.StrictMode>,
  document.getElementById('nekoRoot')
);

reportWebVitals();
