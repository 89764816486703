import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { IUser, IUserState } from '../../interfaces'
import { IConnection } from '../../interfaces/IConnection';
import { ConnectionState } from '../../interfaces/IConnection/IConnectionState';
import { RootState } from '../store'

const initialState: ConnectionState = {
    DISCORD: null,
    GITHUB: null,
    STEAM: null,
    status: 'idle'
};

export const fetchConnections = createAsyncThunk('connections/fetch', async (token) => {
    let connections = await axios.get('/api/users/@me/connections', { headers: { 'authorization': `Bearer ${token}` } })
        .then(res => res.data)

    return connections;
})

export const connectionSlice = createSlice({
    name: 'connections',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchConnections.pending, (state, action) => {
            state.status = 'fetching'
            return state;
        }).addCase(fetchConnections.rejected, (state, action) => {
            state.status = 'rejected'
            return state;
        }).addCase(fetchConnections.fulfilled, (state, action) => {
            action.payload.forEach((connection: IConnection) => {
                state[connection.type] = connection;
            })
            return state;
        })
    }
})
  

export const {} = connectionSlice.actions

export const selectConnections = (state: RootState) => state.connections

export default connectionSlice.reducer