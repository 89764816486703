import React, { ChangeEvent } from "react";
import { toast } from "react-toastify";
import { updateUserSecurity } from "../../../utils/quickRequests";
import classes from "./styles/main.module.scss";
import AvatarEditor from 'react-avatar-editor'

type Props = { close: () => void, refreshUser: () => void, file: File | null }

export function AvatarModal({ close, refreshUser, file }: Props) {

    let ed: any = null;

    let [zoomLevel, setZoomLevel] = React.useState(1.2);

    const save = async () => {

        if (ed) {
            const canvasScaled: HTMLCanvasElement = ed.getImageScaledToCanvas();
            let dataURL = canvasScaled.toDataURL('image/png')
            var blobBin = atob(dataURL.split(',')[1]);
            var array = [];
            for(var i = 0; i < blobBin.length; i++) {
                array.push(blobBin.charCodeAt(i));
            }
            var file=new Blob([new Uint8Array(array)], {type: 'image/png'});

            const formData = new FormData();
            // @ts-ignore
            formData.append('avatar', file, `avatar`);

            try {
                const response = await fetch(`/api/users/@me/avatar`, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        'authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                toast.success('Uploaded');
                refreshUser()
                close()
            } catch(e) {
                toast.error('Error while uploading avatar...')
            }

        }   
    }

    const setEditorRef = (editor: AvatarEditor) => (ed = editor)



    return <div className={classes.modalWrapper}>
        <div className={classes["modalContent"]} style={{ display: 'flex',flexDirection: 'column' }}>
            <AvatarEditor
                className={classes.editor}
                ref={setEditorRef}
                image={file || "/default.png"}
                width={512}
                height={512}
                border={100}
                scale={zoomLevel}
            />
            <input type="range" onChange={(ev) => setZoomLevel(ev.target.valueAsNumber)} name="zoom" id="zoom" min="1" max="3" defaultValue="1.2" step="0.1" />
        </div>
        <footer className={classes["modalFooter"]}>
            <a href="#" onClick={() => close()}>Cancel</a>
            <button className={classes.save} onClick={() => save()}>Save</button>
        </footer>
    </div>
}