import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import { DashboardPage, SignIn, SignUp } from './app/views';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { connect, ConnectedProps } from 'react-redux';
import { fetchUser } from './app/redux/reducers/UserReducer';
import { fetchConnections } from './app/redux/reducers/ConnectionsReducer';
import { OauthPage } from './app/views/oauth';

let connector = connect();
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux

export const Application = ({ dispatch }: Props) => {

  let token = localStorage.getItem('token');
  if(token) {
    // @ts-ignore
    dispatch(fetchUser(token))
    // @ts-ignore
    dispatch(fetchConnections(token))
  }

  return (
    <div className="nekoApplication">
      <ToastContainer />
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <SignIn />
          </Route>
          <Route path="/register" exact>
            <SignUp />
          </Route>
          <Route path="/dashboard">
            <DashboardPage />
          </Route>
          <Route path="/oauth">
            <OauthPage />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default connector(Application);
