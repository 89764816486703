import { faTools } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../redux/store";
import classes from "./styles/generalOverview.module.scss"
import Modal from "react-modal"
import React, { FormEvent } from "react";
import { EmailModal } from "../modals/EmailModal";
import { fetchUser } from "../../../redux/reducers/UserReducer";
import { PasswordModal } from "../modals/PasswordModal";
import { AvatarModal } from "../modals/AvatarModal";
import { ConnectionBlockContent } from "../components/ConnectionsBlock/ConnectionBlockContent";
import { formatAvatar } from "../../../utils/format";

const mapState = (state: RootState) => ({
    user: state.user
})

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux


export function GeneralOverviewComponent({ dispatch, user }: Props) {

    let [state, setState] = React.useState({ currentModal: '' })
    let fileInput: HTMLInputElement | null;
    let [avatarFile, setAvatarfile] = React.useState<File | null>(null);
    let formSubmitter:HTMLButtonElement | null;
    const showModal = (modalName: string) => {
        return setState({
            ...state,
            currentModal: modalName
         })
    }

    let setFormSubmitter = (e: HTMLButtonElement | null) => formSubmitter = e;

    const closeModal = () => setState({ ...state, currentModal: '' });
    // @ts-ignore
    const refreshUser = () => dispatch(fetchUser(localStorage.getItem('token')))

    const setAvatarInput = (input: HTMLInputElement | null) => fileInput = input;
    const onAvatarSelected = (e: FormEvent) => {
        e.preventDefault();
        if(fileInput instanceof HTMLInputElement) {
            let file = fileInput.files && fileInput.files.length > 0 ? fileInput.files[0] : null;
            if(/Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) return alert('Sorry, mobile version is not supported');
            if(file) {
                setAvatarfile(file);
                showModal('avatar')
                console.log(file)
            }
        }
    }

    const test = () => {
        // @ts-ignore
        // document.getElementById('avatarform')?.submit();
        formSubmitter.click();
    }

    return <div className="overview">
        <div className={classes.row}>
            <div className={classes.userblock}>
                <form onSubmit={(e) => onAvatarSelected(e)} id='avatarform'>
                    <input accept="image/*" onChange={() => test()} ref={(input) => setAvatarInput(input)} type="file" name="avatar" id="file" />
                    <label htmlFor="file" className={classes.avatarlabel}>
                        <img srcSet={formatAvatar(user.id || 0, user.avatar || undefined)} src={user.avatar || ""} width="80" height="80" alt="avatar" className={classes.avatar} />
                    </label>
                    <button ref={(b) => setFormSubmitter(b)} type="submit" style={{ display: 'none' }}></button>
                </form>
                <div className={classes.usernameBadges}>
                    <span className={classes.username}>{user.username}</span>
                    {/* <div className="badges">
                        <FontAwesomeIcon className={classes.badge} icon={faTools} />
                    </div> */}
                </div>
            </div>

            <div className={classes.block}>
                <h3>Basic overview</h3>
                <ul className={classes.blockFields}>
                    <li className={classes.field_Centered}>
                        <div className={classes.fieldContent}>
                            <span className={classes.field_title}>NyakoID</span>
                            <span className={classes.field_value}>{`${user.username}#${user.id}`}</span>
                        </div>
                    </li>
                    <li className={classes.field}>
                        <div className={classes.fieldContent}>
                            <span className={classes.field_title}>Email</span>
                            <span className={classes.field_value}>{user.email}</span>
                        </div>
                        <button className={classes.editButton} onClick={() => showModal('email')}>Edit</button>
                    </li>
                    <li className={classes.field}>
                        <div className={classes.fieldContent}>
                            <span className={classes.field_title}>Password</span>
                            <span className={classes.field_value}>*********</span>
                        </div>
                        <button className={classes.editButton} onClick={() => showModal('password')}>Edit</button>
                    </li>
                </ul>
            </div>

            <div className={classes.block}>
                <h3>Connections</h3>
                <ConnectionBlockContent />
            </div>
        </div>

        <Modal isOpen={state.currentModal === 'email'} 
            onRequestClose={closeModal} 
            shouldCloseOnEsc
            shouldCloseOnOverlayClick
            className="modalBody"
            overlayClassName="overlayClassName"
            ariaHideApp={false}
        >
            <EmailModal close={() => closeModal()} refreshUser={refreshUser} />
        </Modal>
        <Modal isOpen={state.currentModal === 'avatar' && avatarFile != null} 
            onRequestClose={closeModal} 
            shouldCloseOnEsc
            shouldCloseOnOverlayClick
            className="modalBody modalBody--staff"
            overlayClassName="overlayClassName"
            ariaHideApp={false}
        >
            <AvatarModal file={avatarFile} close={() => closeModal()} refreshUser={refreshUser} />
        </Modal>
        <Modal isOpen={state.currentModal === 'password'} 
            onRequestClose={closeModal} 
            shouldCloseOnEsc
            shouldCloseOnOverlayClick
            className="modalBody"
            overlayClassName="overlayClassName"
            ariaHideApp={false}
        >
            <PasswordModal close={() => closeModal()} refreshUser={refreshUser} />
        </Modal>
    </div>
}
export const GeneralOverview = connector(GeneralOverviewComponent);