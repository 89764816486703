import { connect, ConnectedProps } from "react-redux";
import { fetchConnections } from "../../../../redux/reducers/ConnectionsReducer";
import { RootState } from "../../../../redux/store";
import classes from "./cb.module.scss";

const mapState = (state: RootState) => ({
    user: state.user,
    connections: state.connections
})

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux

const connectionsList: { name: string, key: 'DISCORD' | 'STEAM' | 'GITHUB', url: string }[] = [
    {
        name: 'Discord',
        key: 'DISCORD',
        url: 'https://discord.com/users/'
    },
    {
        name: 'Github',
        key: 'GITHUB',
        url: ''
    },
    {
        name: 'Steam',
        key: 'STEAM',
        url: 'http://steamcommunity.com/profiles/'
    }
]

export function ConnectionBlockContentComponent ({ dispatch, connections }: Props) {

    const showLoginWindow = (type: 'DISCORD' | 'GITHUB' | 'STEAM') => {
        let win = window.open(`/api/auth/${type.toLowerCase()}/login`, 'Please login', 'width=400,height=800,status=0,toolbar=0');
        if(win != null) {
            var timer = setInterval(function() {   
                if(win != null) {
                    if(win.closed) {  
                        clearInterval(timer);  
                        // @ts-ignore
                        dispatch(fetchConnections(localStorage.getItem('token')));
                    }  
                }
            }, 1000); 
        }
    }

    return <div className={classes.ConnectionBlockContent}>
        <ul className={classes.fields}>
            {connectionsList.map(connection => {
                return <li className={classes.field} key={connection.key}>
                    <div className={classes.fieldContent}>
                        <div className={classes.platformAvatar}>
                            {connections[connection.key] ? <img src={connections[connection.key]?.platform_avatar || "/default.png"} width="36" height="36" alt="img" /> : ""}
                        </div>
                        <div className={classes.platformInfo}>
                            <span className={classes.platformName}>
                                {connection.name}
                            </span>
                            <span className={classes.platformUsername}>
                                {connections[connection.key]?.platform_username || "Unlinked"}
                            </span>
                        </div>
                    </div>

                    {connections[connection.key]?.platform_id ? 
                        <button onClick={() => showLoginWindow(connection.key)} className={classes.relink}>Edit</button> :
                        <button onClick={() => showLoginWindow(connection.key)} className={classes.Link}>Link</button>}
                </li>
            })}
        </ul>
    </div>
}

export const ConnectionBlockContent = connector(ConnectionBlockContentComponent);