import classes from "./oauth.module.scss";

import { connect, ConnectedProps } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { RootState } from "../../redux/store";
import { FormEvent } from "react";
import { fetchOauthClient } from "../../redux/reducers/OAuthReducer";
import Logo from "../../assets/img/logo.svg";
import LogoFull from "../../assets/img/logo_full.svg"
import checkmark from "../../assets/img/checkmark.svg"
import axios from "axios";
import { formatAvatar } from "../../utils/format";
import { logoutUser } from "../../redux/reducers/UserReducer";
const mapState = (state: RootState) => ({
    user: state.user,
    oauth: state.oauth
})

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux

export function OauthComponent({ dispatch, user, oauth }: Props) {

    
    if(!user.id) {
        if(user.status !== 'fetching' || !localStorage.getItem('token')) {
            localStorage.setItem('backUrl', `${window.location.pathname}${window.location.search || ""}`);
            return <Redirect to="/" />
        }
    }


    if(!oauth.user) {
        if(oauth.status === 'rejected') {
            return  <div className={classes.page}>
                <div className={classes.logo}>
                    <picture>
                        <source srcSet={LogoFull} media="(max-width: 750px)" />
                        <img src={Logo} alt="LOGOTYPE" />
                    </picture>
                </div>
    
                <form className={classes.form}>
                    Invalid form
                </form>
            </div>
        }
        // @ts-ignore
        if(oauth.status !== 'fetching') dispatch(fetchOauthClient(window.location.search))
    }

    let scopes: string[] = [];

    let url = new URLSearchParams(window.location.search);
    let scope = url.get('scope');

    if(scope) {
        let scopeList = scope.split(' ', );
        scopeList.forEach(scope => {
            if(scope === 'identity') {
                scopes.push('Access your username, avatar');
            }
            if(scope === 'email') {
                scopes.push('Access your email');
            }
        })
    }

    if(oauth.status === 'fetching')
        return <Loader />

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        let body = await axios.post(`/api/oauth2/authorize${window.location.search}`, {
            authorize: true
        }, { headers: {'authorization': localStorage.getItem('token')} }).then(res => res.data).catch(e => null);

        if(body.code) {
            return window.location.href = `${body.redirect_uri}?code=${body.code}`
        }
    }

    return <div className={classes.page}>
        <div className={classes.logo}>
            <picture>
                <source srcSet={LogoFull} media="(max-width: 750px)" />
                <img src={Logo} alt="LOGOTYPE" />
            </picture>
        </div>

        <form className={classes.form} onSubmit={onSubmit}>
            <header className={classes.hd}>
                <div className={classes.headerIcons}>
                    <img src={formatAvatar(oauth.user?.id || 0, oauth.user?.avatar || undefined) || ""} width="100" height="100" alt="" />
                    <div className={classes.ellipseGroup}>
                        <div className={classes.ellipse}>
                            <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle r="3" transform="matrix(1 0 0 -1 3 3.5)" fill="#4A4A4A"/>
                            </svg>
                        </div>
                        <div className={classes.ellipse}>
                            <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle r="3" transform="matrix(1 0 0 -1 3 3.5)" fill="#4A4A4A"/>
                            </svg>
                        </div>
                        <div className={classes.ellipse}>
                            <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle r="3" transform="matrix(1 0 0 -1 3 3.5)" fill="#4A4A4A"/>
                            </svg>
                        </div>
                    </div>
                    <img src={oauth.application?.icon || ""} width="100" height="100" alt="" />
                </div>
                <div className={classes.description}>
                    <span className={classes.applicationName}>
                        {oauth.application?.name}
                    </span>
                    <span className={classes.wantAccess}>
                        wants to access your account 
                    </span>
                    <span className={classes.loggedAs}>
                        NyakoID: {user.username}#{user.id} (<a style={{cursor: 'pointer'}} onClick={() => {
                            localStorage.setItem('backUrl', `${window.location.pathname}${window.location.search || ""}`);
                            dispatch(logoutUser())
                        }}>Not you?</a>)
                    </span>
                </div>
            </header>
            <section className={classes.content}>
                <div className={classes.scopeInfo}>
                    THIS WILL ALLOW THE DEVELOPER OF KANADE TO:
                    <ul className={classes.scopes}>
                        {scopes.map((scope, index) => {
                            return <li className={classes.scope} key={index}>
                                <img src={checkmark} alt="" />
                                {scope}
                            </li>
                        })}
                    </ul>
                </div>
            </section>

            <section className={classes.actionMenu}>
                    <Link to="/">Cancel</Link>
                    <button className={classes.agreeButton}>Authorize</button>
            </section>
                
        </form>
    </div>
}

export const OauthPage = connector(OauthComponent);
